import './Footer.css';

function Footer() {
    return (
        <div className='footer_div'>
            <img src='/MehdiParyavi_Blog_Logo (1).png' />
            <p>{"Copyright © 2023 Mehdi Paryavi's Data Center Blog."}</p>
        </div>
    );
}

export default Footer;
